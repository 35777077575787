
@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Stoke:wght@300&display=swap');



* {
  box-sizing: border-box;
}

.form {
  display: flex;
  position: absolute;
  text-align: center;  
  flex-direction: row;
  justify-content: center;
  background-color: #1E1F1B;
  padding: 100px 10px;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
}

.all-inputs {
  width: 300px;
}

.card-wrapper {
  position: relative;
}

.card-wrapper:hover .delete-btn {
  display: block;
}

.delete-btn {
  display: none;
  position: absolute;
  bottom: 60px;
  right: 60px;
  background: white;
  border: none;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.form-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
  border-radius: 20px;
  border: none;
  color: white;
}

.open-btn {
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
  border-radius: 100%;
  border: none;
  color: white;
  padding: 10px;
  position: fixed;
  right: 30px;
  bottom: 50px;
  z-index: 11;
}

.close-btn {
  position: absolute;
  right: 20px;
  top: 50px;
  border: none;
  border-radius: 100%;
  background: none;
  color: white;
  font-size: 25px;
}

.input {
  width: 300px;
  margin: 20px 0;
  border-radius: 10px;
  padding: 6px 10px;
  background-color: #e6e6e6;
  border: none;
}

.input:focus {
  outline: none;
}

body, html {
  background-color: #1E1F1B;
}

.minus-padding {
  margin-top: -60px;
  padding-top: 100px;
  background-color: #1E1F1B;
}





/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.App {
  text-align: center;

}

.navigation {
  background: none;
  position: relative;
  z-index: 1;
  font-size: 16px;
}

.navbar-collapse {
  margin-right: 60px!important;
}


.navbar-toggler {
  background: rgba(255, 255, 255, 0.316)!important;
}

.navbar-light .navbar-nav .nav-link {
  color:#E1E1E1!important;
  font-family: 'Sulphur Point', sans-serif;
  margin-top: 0!important;
  padding-top: 0!important;
}

.show {
  padding: 0px 0 90px;
  margin-bottom: 30px;

}


/* HERO ***********************************************************************************************************/

.hero {
  margin-top: -70px;
  background: url(./images/hero.jpg);
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}



.hero-text-wrapper {
  position: relative;
  width: 50%;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero-title {
  font-family: 'Sulphur Point', sans-serif;
  color: #CCCCCC;
  letter-spacing: 20px;
  font-size: 49px;
  margin-top: -100px;
	-webkit-animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck-bottom 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}



.hero-txt {
  font-family: 'Sulphur Point', sans-serif;
  color: #E4E4E4;
  font-size: 25px;
  padding: 30px 150px;
  margin-top: 30px;
  -webkit-animation: puff-in-center 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: puff-in-center 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation-delay: 1s;
}

.btn-seemore {
  padding:10px 40px;
  background: rgba(0, 0, 0, 0.103);
  color: white;
  border: 2px solid  #207EA6;
  font-family: 'Sulphur Point', sans-serif;
  border-radius: 6px;
  cursor: pointer;
  z-index: 2;
  position: relative;
  letter-spacing: 5px;
  font-size: 20px;
  -webkit-animation: puff-in-center 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: puff-in-center 1s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation-delay: 1.4s;
}



.logo {
  -webkit-animation: slide-in-bck-center 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bck-center 3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}






/* ABOUT US ************************************************************************************ */
.aboutus {
  background: -webkit-linear-gradient(#1D1E1A, #242424);
  margin-bottom: -20px; 
  height: 600px; 
  color: #ADADAD;
  padding: 100px 100px;

}

.title-big {
  background: -webkit-linear-gradient(#47BCEE, #207EA6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 5px;
  font-family: 'Stoke', serif;
  font-weight: bold;
  font-size: 40px;
}

.about {
  text-align: left;
}

.about-txt {
  font-size: 18px;
  font-family: 'Sulphur Point', sans-serif;
}

.img-about {
  border-radius: 10px;
}


.img-two {
  margin-left: 200px;
  margin-top: -150px;
}

.imgs-about {
  margin-top: -20px;
  margin-left: 60px;
}

.brdo {
  width: 100%;
}





/* QUOTE ******************************************************************************* */
.quote-component {
  height: 100%;
  background: rgb(160, 160, 160);
  margin-bottom: -10px;
}

.quote-txt {
  font-size: 26px;
  color: white;
  font-family: 'Stoke', serif;
  position: relative;
  z-index: 1;
}

.pisac {
  color: white;
  font-size: 17px;
  font-family: 'Stoke', serif;
  font-weight: bold;
}


.drvece {
  margin-bottom: -16px;
  margin-top: -240px;
}

.abonos-stol-slika {
  background-position: right;
}


/* ABONOs ******************************************************************** */
.abonos-component {
  height: 700px;
}
.abonos {
  text-align: left;
}

.abonos-img {
  
margin-left: -50px;
border-radius: 10px;
margin-top: 50px;
}


.klupe {
  background-position: bottom;
}


/* CONTACT *************************************************************** */
.contact {
  background: #1E1F1B;
  color: white;
  padding-top: 100px;
  margin-top: -60px;
}





/* SEPARATOR ********************************************************************* */



.separator-component{
  display: flex;
  justify-content: space-around;
  padding-top: 100px;
  margin-top: -60px;
  background-color: #1E1F1B;
}

.separator-item {
  display: flex;
  justify-content: center;
}

a:hover {
  text-decoration: none!important;
}

.separator-first {
  border: none;
  height: 300px;
  width: 300px;
  background-size: cover;
  font-size: 40px;
  color: white;
  border-radius: 10px;
  margin-top: 20px;
  overflow: hidden;
}


.separator-label {
  background-color: rgba(0, 0, 0, 0.59);
  font-size: 27px;
  font-family: 'Sulphur Point', sans-serif;
  transition: all .4s cubic-bezier(1, 0.03, 0.51, 0.85);
}

.separator-first:hover .separator-label {
  padding:140px 0;
  color:#45B8E9;
  background-color: rgba(0, 0, 0, 0.79);
}



/* FOOTER ************************************************** */
.footer {
  color: rgb(175, 175, 175);
  background-color: #1E1F1B;
  padding: 200px 0;
}

.footer-label {
  background: -webkit-linear-gradient(#47BCEE, #207EA6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-weight: 100;
}



.footer-item {
  border-bottom: 1px solid #4f4f4b;
  border-top: 1px solid #1E1F1B;
  padding: 10px;
  display: inline-block;
  width: 240px;
  transition: all 0.5s ease;
}

.footer-placeholder {
  color: #525251;
}

.footer-item:hover {
  width: 300px;
  border-top: 1px solid #4f4f4b;
}




@media only screen and (max-width: 1444px) {
  .hero-text-wrapper {
    width: 90%;
  }

.quote-txt {
  margin-top: -40px;
  position: relative;
  z-index: 2;
}

}


@media only screen and (max-width: 1040px) {
  .about-txt {
    font-size: 13px;
}

.aboutus {
  padding: 100px 20px;
}
.quote-txt {
  font-size: 25px;
  padding-top: 140px;
}

}


@media only screen and (max-width: 771px) {
  .aboutus {
    height: 900px;
    padding: 60px 20px;
  }

  .imgs-about {
    margin: 20px 30px;
  }

  .abonos-component {
    margin-top: -30px;
  }

  .hero-txt {
    padding:  30px 0;
  }

}





@media only screen and (max-width: 1000px) {
  .navbar-light .navbar-nav .nav-link {
    color:#e2e2e2!important;
  }
  .navbar-collapse {
    margin-right: 0!important
  }
}



@media only screen and (max-width: 500px) {

  .hero {
    height: 110vh;
  }


  .footer{
    padding: 20px 0;
  }


  .separator-component{
    flex-direction:column;
    justify-content: space-around;
    padding-right: 20px;
    padding-left: 20px;
  }

  .separator-first {
    width: 300px;
    height: 300px;
    margin-top: 20px;
  }  
  .hero-text-wrapper {
    top:50%;
  }


  .hero-txt {
    font-size: 16px;

  }

  .logo {
    margin-left: -7px!important;
    width: 300px!important;
  }

  .title-big {
    font-size: 30px;
  }

  .imgs-about {
    margin: 0;
    margin-left: -50px;
  }

  .quote-component {
    margin-bottom: 10px;
  }

  .img-about {
    margin: 0;
    width: 150px!important;
  }

  .img-two {
    margin-top: -150px;
    margin-left: 150px;
  }

  .about-component {
    margin-top: 0px;
    height: 750px!important;
  }

  .abonos-img {
    width: 250px!important;
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .abonos-about {
    margin-bottom: 20px;
  }

  .drvece {
    margin-bottom: 10px;
    margin-top: -20px;
  }

  .footer {
    padding-top:80px ;
  }
}












/* ----------------------------------------------
 * Generated by Animista on 2021-2-3 22:17:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation focus-in-contract-bck
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}


@-webkit-keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}




@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}



